<template>
  <div v-if="visible">
    <el-table :data="tableData" style="width: 100%" v-loading="loading" element-loading-text="加载中">
      <el-table-column prop="deviceTitle" label="设备名称" width="180"></el-table-column>

      <el-table-column prop="status" label="状态" width="180"></el-table-column>

      <el-table-column prop="expire" label="服务到期时间" width="180"></el-table-column>

      <el-table-column prop="time" label="短信发送间隔" width="180">
        <template slot-scope="scope">
          <span @click="dialogVisible = true">
            {{ scope.row.time }}
            <i class="el-icon-arrow-right"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <p v-if="user.isAdmin">以下功能只有管理员可见</p>
    <p v-else>如有需要，请联系设备销售人员来开通短信业务</p>

    <div class="btn" v-if="user.isAdmin">
      <el-button type="success" size="small" @click="onSubmit">开通短信业务</el-button>
      <el-button size="small" type="danger" @click="closeMsg">关闭短信业务</el-button>
    </div>

    <el-dialog title="短信发送间隔(分钟)" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-input v-model="time" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="modalSure">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      user: '',
      visible: true,
      isAdmin: false,
      dialogVisible: false,
      uid: '',
      time: '',
      tableData: [{
        status: '',
        expire: '',
        time: '',
        deviceTitle: ''
      }],
      device: {
        uid: '',
        model: '',
        title: '',
      },
      loading: true,
      timeCount: 0

    }
  },
  created() {
    if (this.$store.state.treeData.length) {
      let obj = this.$store.state.treeData[0].children
      let path = '全部设备' + ' > ' + obj[0].title + ' > ' + obj[0].children[0].title

      // this.$nextTick(function () {
      //   this.$parent.$parent.setNodePath(path);
      // })
      setTimeout(() => {
        this.$parent.$parent.setNodePath(path);
      }, 500)

      let first = obj[0].children[0]
      if (first.uid == undefined) {   //防止设备树层级过多
        first = obj[0].children[0].children[0]
      }
      this.uid = first.uid
      let { uid, title } = first;
      this.tableData[0].deviceTitle = title ? title : uid

      this.getData(uid)
    }
  },
  watch: {
    '$store.state.curTreeData': {
      handler(val) {
        if (val[0].type == "device") {
          this.loading = true
          this.setLoadingtimeOut("start")
          let [{ uid, title }] = val;
          this.tableData[0].deviceTitle = title ? title : uid
          this.getData(uid)
          this.uid = uid
        }
      }
    }
  },
  mounted() {
    this.ws.addCallback('devicesAck', (res) => {
      let obj = this.$store.state.treeData[0].children
      let path = '全部设备' + ' > ' + obj[0].title + ' > ' + obj[0].children[0].title
      this.$parent.$parent.setNodePath(path);

      let first = obj[0].children[0]
      if (first.uid == undefined) {   //防止设备树层级过多
        first = obj[0].children[0].children[0]
      }
      this.uid = first.uid
      let { uid, title } = first;
      this.tableData[0].deviceTitle = title ? title : uid

      this.getData(uid)
    })

    this.user = this.common.cGetUser();

    this.ws.addCallback('smsConfigAck', (res) => {
      let interval = ""
      if (res.interval) {
        interval = parseInt(res.interval) / 60;//转成分钟
        if (interval < 1) {
          interval = 60;
        }
      }

      this.time = interval

      this.tableData[0].expire = res.expire ? res.expire : "-"
      this.tableData[0].status = (res.enable == 1) ? "已开通" : "没有开通"
      this.tableData[0].time = this.disarmTimeout2Desc(interval)
      this.setLoadingtimeOut("close")
      this.loading = false

    })
  },
  methods: {
    modalSure() {
      let minute = parseInt(this.time)

      const maxMinute = 24 * 60 * 1
      if (minute <= 0) {
        minute = 60
      } else if (minute > maxMinute) {
        minute = maxMinute
      }

      this.tableData[0].time = this.disarmTimeout2Desc(minute)

      let json = {
        "cmd": "setSmsConfig",
        "uid": this.uid,
        "interval": minute * 60
      };
      this.ws.send(json)

      this.ws.addCallback("setSmsConfigAck", (res) => {
        this.$message('配置成功,请点击下方开通短信业务才能生效！');
        this.dialogVisible = false
      })

    },
    handleClose(done) {
      done();
    },
    getData(val) {

      let json = {
        "cmd": "smsConfig",
        "uid": val
      }
      this.ws.send(json);

    },
    onSubmit() {
      let json = {
        "cmd": "enableSms",
        "uid": this.uid,
      };
      this.ws.send(json);
      this.ws.addCallback("enableSmsAck", (res) => {
        this.$message('短信业务已开通!');
        this.getData(this.uid)
      })

    },

    closeMsg() {
      let json = {
        "cmd": "disableSms",
        "uid": this.uid
      };
      this.ws.send(json);
      this.ws.addCallback("disableSmsAck", (res) => {
        this.$message('短信业务已关闭!');
        this.getData(this.uid)
      })
    },
    disarmTimeout2Desc(minute) {
      if (minute <= 0)
        minute = 60;

      let hours = Math.floor(minute / 60);
      if (hours == 0) {
        return minute + "分钟";
      }

      minute -= hours * 60;
      if (minute > 0) {
        return hours + " 小时 " + minute + " 分";
      }

      return hours + " 小时";
    },
    setLoadingtimeOut(val) {
      let state = ""
      clearTimeout(this.timeCount);
      if (val == "start") {
        state = setTimeout(() => {
          if (this.loading) {
            this.common.cToast(this, "获取数据失败，请刷新后重试");
            this.loading = false
          }
        }, 5000)
        this.timeCount = state
      } else {
        clearTimeout(state);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  margin-top: 30px;
}
</style>